// @ts-nocheck

import {
  Vue, Options
} from 'vue-class-component'
import * as bootstrap from 'bootstrap'
import Header from '@/components/Header.vue'
import Utils from '@/utils/utils'
import swal from 'sweetalert'
import { customerFactory } from '@/http/customer'
import { masterDataFactory } from '@/http/master-data'
import { usFactory } from '@/http/uang-sekolah'
import constants from '@/constants/constants'
declare var snap: any
declare var html2pdf: any

@Options({
  components: {
    Header
  },
  data() {
    return {
      currentSort:'name',
      currentSortDir:'asc',
      billDetails: [],
      months: [],
      school: {
        name: ''
      },
      student: {
        name: '',
        class: {
          name: ''
        },
        student_profile: {
          nis: ''
        }
      },
      totalPaid: 0,
      billId: 0,
      data_master: {
        months: "1",
        school:"1"
      },
      orderId: this.$route.params.order_id,
      order: {
        total_charge: 0,
        total_payment: 0,
        us_order_id: 0,
        status: 0,
        us_order: {
          status: 0
        }
      },
      billUserInstallments: [],
      constants: constants,
      xenditModal: null,
      xenditInvoice: "",
      bcaModal: null,
      bca: {
        orderId: '',
        va_number: '',
        va_number_nobill: '',
        amount: 0,
        convenience_fee: 0,
        merchant_fee: 0,
        total_discount: 0,
        ppn: 0,
        total_payment: 0
      },
      customInvoice: false,
      additional_data: '',
    }
  },
  methods: {
    print: function() {
      if (typeof html2pdf === 'undefined') {
        swal("Invoice Belum siap", "", "error")
        return
      }
      // Get HTML to print from element
      var prtHtml = document.getElementById('print')?.innerHTML

      var opt = {
        margin: 0,
        filename: `invoice_#${this.order.invoice_no}.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
      }
      html2pdf().from(prtHtml).set(opt).save()
    },
    sort:function(s:any) {
      //if s == current sort, reverse
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
      }
      this.currentSort = s
    },
    beautifyDatetime: function(date:any) {
      return Utils.beautifyDatetime(date)
    },
    toLocaleString: function(val:any) {
      return Utils.toLocaleString(val)
    },
    getMonth: function(date:any) {
      if (date != '' && date != undefined) {
        const d = date.split("-")
        const month  = d[2]
        return this.months[month].id
      } else {
        return ''
      }
    },
    addSnapJS () {
      var uangsekolah = document.createElement('script')
      var instance = String(localStorage.getItem('instance'))
      uangsekolah.setAttribute('src', localStorage.getItem('base_url') + '/' + instance + '/us/snap.js')
      document.head.appendChild(uangsekolah)
    },
    addPdfHtmlJS () {
      var htmljs = document.createElement('script')
      htmljs.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.js')
      document.head.appendChild(htmljs)
    },
    handleExpiredPayment: function() {
      var spp_user_bill_ids :any = []
      this.billUserInstallments.forEach((value:any) => {
        spp_user_bill_ids.push(value.spp_bill_user_id)
      })
      this.$router.push({
        path: '/user/bill',
        query: {
          repay_user_bill_ids: spp_user_bill_ids,
        }
      })
      /* var spp_user_bill_ids :any = []
      this.billUserInstallments.forEach((value:any) => {
        spp_user_bill_ids.push(value.spp_bill_user_id)
      })
      var data = {
        spp_user_bill_ids: spp_user_bill_ids
      }
      Promise.all([
        customerFactory.checkoutOrder(data)
      ]).then(results => {
        if (results[0].error == false) {
          var us_order_id = results[0].data.order.us_order_id
          this.handlePayment(us_order_id)
        } else  if (results[0].response.data.error == true) {
          var errorMessage = results[0].response.data.messages
          swal(errorMessage, "", "error")
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      }) */
    },
    handlePayment: function(us_order_id:any) {
      Promise.all([
        usFactory.getUSToken(us_order_id)
      ]).then(results => {

        var token_order_id = results[0].data.us_order.token
        var us_order = results[0].data.us_order
        var order = results[0].data.order
        var merchant = us_order.merchant

        if (!merchant) {
          swal("Gagal melakukan request", "", "error")
          return
        }

        if (merchant.type == 'midtrans') {
          if (typeof snap === 'undefined') {
            swal("Halaman Belum siap", "", "error")
            return
          }

          snap.pay(token_order_id, {
            uiMode: "qr",
            gopayMode: "qr",
            onSuccess: function(result:any){
              console.log('success')
              console.log(result)
              return false
            },
            onPending: function(result:any){
              console.log('pending')
              console.log(result)
              return false
            },
            onError: function(result:any){
              console.log('error')
              console.log(result)
              return false
            },
            onClose: function(){
              console.log('customer closed the popup without finishing the payment')
              return false
            }
          })
        } else if (merchant.type == 'xendit') {
          this.xenditInvoice = us_order.link
          this.xenditModal.show()
        // } else if (merchant.type == 'bca' || merchant.type.includes('offline') || merchant.type == 'cimb') {
        } else {
          const bankNames = {
            bca: 'BCA',
            panin: 'Panin',
            cimb: 'CIMB',
            bcah2h: 'BCA',
            permata: 'Permata',
            ocbc: 'OCBC',
            mandiri: 'Mandiri',
            bni: 'BNI',
            bri: 'BRI'
          }

          this.bca.orderId = us_order.order_id
          this.bca.va_number = us_order.custom_field1
          if(merchant.type.includes('offline')) {
            this.bca.va_number_nobill = us_order.custom_field2
            this.bca.bank_name = bankNames[merchant.type.split('-')[0]]
          } else {
            this.bca.bank_name = bankNames[merchant.type]
          }
          this.bca.amount = us_order.total_amount
          this.bca.convenience_fee = order.convenience_fee
          this.bca.merchant_fee = order.merchant_fee
          this.bca.total_discount = order.total_discount
          this.bca.ppn = order.ppn
          this.bca.total_payment = order.total_payment
          this.bcaModal.show()
        }

      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      }).finally(() => {
        this.loadData()
      })
      return false
    },
    loadData: function() {
      Promise.all([
        customerFactory.detailOrder(this.orderId)
      ]).then(results => {
        this.customInvoice = results[0].data.custom_invoice
        this.order = results[0].data.order
        this.student = results[0].data.student
        this.additional_data = results[0].data.additional_data
        this.billUserInstallments = results[0].data.bill_user_installments
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    loadDataMaster: function() {
      Promise.all([
        masterDataFactory.master_datas(this.data_master)
      ]).then(results => {
        this.months = results[0].data.months
        this.school = results[0].data.school
        this.loadData()
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    copyValue: async function(mytext) {
      try {
        await navigator.clipboard.writeText(mytext);
        alert('Berhasil Copy');
      } catch($e) {
        alert('Gagal Copy');
      }
    },
    formatPrice(value) {
      let val = Math.round(value/1).toFixed(0).replace('.', ',')
      return 'IDR ' + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    getCustomInvoice: async function() {
      Promise.all([
        customerFactory.getCustomInvoice(this.orderId)
      ]).then(results => {
        if (results[0].error == false) {
          let inv = results[0].data
          this.downloadPDF(inv.data_url_file)
        } else {
          swal("Gagal melakukan request", "", "error")
        }
      }).catch((e) => {
        swal("Gagal melakukan request", "", "error")
        console.log(e)
      })
    },
    downloadPDF: function(pdf) {
      const linkSource = pdf;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `invoice_#${this.order.invoice_no}.pdf`;
      downloadLink.click();
    }
  },
  async mounted () {
    var xenditModal = document.getElementById('xenditModal')
    if (xenditModal != null) {
      this.xenditModal = new bootstrap.Modal(xenditModal)
    }

    var bcaModal = document.getElementById('bcaModal')
    if (bcaModal != null) {
      this.bcaModal = new bootstrap.Modal(bcaModal)
    }

    await this.loadDataMaster()

    this.addSnapJS()
    this.addPdfHtmlJS()
  }

})

export default class UserInvoice extends Vue {}
